//售后退款接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-02-1
 */

//售后退款分页查询
export function refundPageList(parameter){
    return axios({
        url: '/refund/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//售后退款-审核
export function refundAudit(parameter){
    return axios({
        url: '/refund/audit',
        method: 'post',
        data: parameter 
    })
}
//售后退款-商家确认收货
export function refundOk(parameter){
    return axios({
        url: '/refund/confirmReceipt',
        method: 'post',
        params: parameter 
    })
}
//售后退款详情
export function refundDetail(parameter){
    return axios({
        url: '/refund/detail',
        method: 'post',
        params: parameter 
    })
}

