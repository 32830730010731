<template>
  <a-modal title="详情" :width="900" :visible="visible" :destroyOnClose="true" @cancel="handleCancel" :footer="null">
    <a-spin :spinning="confirmLoading">
      <a-row>
        <a-form layout="inline">
          <a-col>
            <a-row>
              <a-col :md="24" :sm="24">
                <a-form-item label="昵称">
                  <span>{{ details.nickname }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24">
                <a-form-item label="订单号">
                  <span>{{ details.orderNo }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24">
                <a-form-item label="实付金额（元）">
                  <span>{{ details.payPrice }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24">
                <a-form-item label="退款原因">
                  <span>{{ details.refundReason }}</span>
                </a-form-item>
                <!-- refuseReason -->
              </a-col>
              <a-col v-if="details.refundImages" :md="24" :sm="24">
                <a-form-item label="申请配图">
                  <viewer :images="details.refundImages.split(',')">
                    <img
                      v-for="(item, index) in details.refundImages.split(',')"
                      :key="index"
                      :src="item"
                      alt=""
                      style="border: 0.5px solid #ccc; padding: 3px; margin: 3px"
                    />
                  </viewer>
                </a-form-item>
              </a-col>
              <a-col
                v-if="
                  (details.refundType == 1 && details.status == 3) || (details.refundType == 2 && details.status == 5)
                "
                :md="24"
                :sm="24"
              >
                <a-form-item label="拒绝原因">
                  <img :src="details.refuseReason" alt="" />
                </a-form-item>
              </a-col>
            </a-row>
            <!-- 退货退款物流 -->
            <a-row v-if="details.refundType == 2 && details.status >= 3">
              <a-divider>退货物流</a-divider>
              <a-col :md="12" :sm="24">
                <a-form-item label="物流公司">
                  <span>{{ details.comLogistics }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="物流单号">
                  <span>{{ details.comCourierId }}</span>
                </a-form-item>
              </a-col>
            </a-row>
            <!-- 商品信息 -->
            <a-row>
              <a-divider>商品信息</a-divider>
              <a-col :md="24" :sm="24">
                <a-table :columns="columns" bordered :data-source="data" :scroll="{ x: 580 }" :pagination="false">
                  <!-- 规格类型插槽 -->
                  <span slot="speType" slot-scope="text">
                    <span>{{ text == 1 ? '单规格' : text == 2 ? '多规格' : '' }}</span>
                  </span>
                  <!-- 商品图片插槽 -->
                  <span slot="goodsImage" slot-scope="text">
                    <viewer :images="[text]">
                      <img :src="text" />
                    </viewer>
                  </span>
                  <!-- 规格图片插槽 -->
                  <span slot="specImage" slot-scope="text">
                    <viewer :images="[text]">
                      <img :src="text" />
                    </viewer>
                  </span>
                </a-table>
              </a-col>
            </a-row>
          </a-col>
        </a-form>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { refundDetail } from '@/api/modular/mallLiving/refund'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '80px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '商品名称',
    align: 'center',
    dataIndex: 'goodsName',
    width: '200px',
    key: 'goodsName',
  },
  {
    title: '商品图片',
    dataIndex: 'goodsImage',
    width: '100px',
    key: 'goodsImage',
    align: 'center',
    scopedSlots: { customRender: 'goodsImage' },
  },
  {
    title: '规格类型',
    dataIndex: 'speType',
    width: '80px',
    key: 'speType',
    align: 'center',
    scopedSlots: { customRender: 'speType' },
  },
  {
    title: '规格',
    dataIndex: 'specName',
    width: '120px',
    key: 'specName',
    align: 'center',
  },
  {
    title: '规格图片',
    dataIndex: 'specImage',
    width: '120px',
    key: 'specImage',
    align: 'center',
    scopedSlots: { customRender: 'specImage' },
  },
  {
    title: '购买数量',
    dataIndex: 'buyNum',
    width: '80px',
    key: 'buyNum',
    align: 'center',
  },
  {
    title: '退货数量',
    dataIndex: 'refundNum',
    width: '80px',
    key: 'refundNum',
    align: 'center',
  },
  {
    title: '单价（元）',
    dataIndex: 'unitPrice',
    width: '80px',
    key: 'unitPrice',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: '150px',
    key: 'createTime',
    align: 'center',
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      visible: false, //modal框显示状态
      confirmLoading: false,
      id: '',
      details: {},
      form: this.$form.createForm(this),
    }
  },
  methods: {
    //初始化方法
    detail(record) {
      this.visible = true
      this.confirmLoading = true

      //获取详情数据
      setTimeout(() => {
        refundDetail({ id: record.id })
          .then((res) => {
            if (res.success) {
              this.details = res.data
              this.data = res.data.orderGoodsList
              this.confirmLoading = false
            }
          })
          .finally((res) => {
            setTimeout(() => {
              this.confirmLoading = false
            }, 5000)
          })
      }, 100)
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false
      this.confirmLoading = false
      setTimeout(() => {
        this.details = {} //关闭之后清空
      }, 100)
    },
  },
}
</script>
<style lang="less" scoped>
.mytable {
  margin-bottom: 70px;
  border-collapse: collapse;
  width: 100%;
  height: 250px;
  .title {
    background: rgb(207, 248, 248);
    width: 20%;
  }
  td {
    border: 2px solid rgb(228, 225, 225);
    padding: 7px;
    font-size: 15px;
    width: 30%;
  }
}
img {
  width: 80px;
}
</style>
